import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import {
    socialMediaPostsContainer,
    tweetContainer,
} from "./social-media-post-list.module.scss"

import sanitizeHtml from "sanitize-html"
import Button from "./button"

const sanitizeHtmlOptions = {
    allowedTags: ["b", "i", "em", "strong", "a", "blockquote", "p", "br"],
    allowedAttributes: {
        a: ["href"],
        blockquote: ["class", "data-conversation"],
        p: ["lang", "dir"],
    },
}
const prohibitedScript =
    '<script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>'
const sanitizeTweet = tweet => {
    if (tweet.includes(prohibitedScript)) {
        tweet = tweet.replace(prohibitedScript, "")
    }
    if (tweet.includes("<br>")) {
        tweet = tweet.replace(/<br>/g, "<br/>")
    }
    tweet = sanitizeHtml(tweet, sanitizeHtmlOptions)
    return tweet
}

const comparePriority = (a, b) => {
    const priorityComparison = (b.priority || 0) - (a.priority || 0)
    if (priorityComparison === 0) {
        return a.parent.name < b.parent.name ? -1 : 1
    }
    return priorityComparison
}

const renderPost = node => {
    if (node.tweet) {
        return (
            <div
                key={node.id}
                className={tweetContainer}
                dangerouslySetInnerHTML={{
                    __html: sanitizeTweet(node.tweet),
                }}
            />
        )
    }
    if (node.linkedin) {
        return (
            <div
                key={node.id}
                dangerouslySetInnerHTML={{
                    __html: node.linkedin,
                }}
            />
        )
    }

    if (node.external) {
        return (
            <div
                key={node.id}
                dangerouslySetInnerHTML={{
                    __html: node.external,
                }}
            />
        )
    }

    if (node.link) {
        return (
            <blockquote
                key={node.id}
                style={{ textAlign: "left", marginTop: "1em" }}
            >
                {node.content}
                <div>
                    <Button link={node.link}>View full post</Button>
                </div>
            </blockquote>
        )
    }
}

const SocialMediaPostList = ({ socialMediaPosts }) => {
    socialMediaPosts.sort(comparePriority)
    return (
        <div className={socialMediaPostsContainer}>
            {socialMediaPosts.map(node => renderPost(node))}
        </div>
    )
}
SocialMediaPostList.propTypes = {
    socialMediaPosts: PropTypes.array.isRequired,
}
export default SocialMediaPostList

export const query = graphql`
    fragment SocialMediaPostFragment on SocialMediaPostsJson {
        id
        tweet
        linkedin
        external
        link
        content
        talk
        priority
        parent {
            ... on File {
                id
                name
            }
        }
    }
`
